import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';

export function ViewProductionStagesForOrder() {

    const [post, setPost] = React.useState(null);
    const [post2, setPost2] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost2(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post2 != null && post2 != "") {
            var postModified2 = post2.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                orderId: ""
            };

            const handleChange = event => {
                state.orderId = event.value.id;
            };

            const handleAction = event => {

                const formData = new FormData();
                formData.append("orderId", state.orderId);

                if (document.getElementById("myTableBody") != null) {
                    document.getElementById("myTableBody").remove();
                }

                var y = document.createElement("tbody");
                y.setAttribute("id", "myTableBody");
                document.getElementById("myTable").appendChild(y);

                var stockDict = {};

                var url = "https://cascamailsender.azurewebsites.net/getpackingmaterialstock?orderId=" + state.orderId;
                axios.get(url)
                    .then((response) => {
                        response.data.forEach((packingMaterialStock) => {

                            var key = packingMaterialStock.packingVariant + "_" + packingMaterialStock.packingType
                            if (stockDict[key] == null) {
                                stockDict[key] = packingMaterialStock.quantity;
                            }
                        });

                        axios.post("https://cascamailsender.azurewebsites.net/completeprocessfororder", formData)
                            .then((response) => {

                                var productionProcess = response.data.productionProcess;
                                var productionProcessBatch = response.data.productionProcessBatch;

                                var inputpartyname = document.getElementById("partyName");
                                var inputproductname = document.getElementById("productName");
                                var inputcomp = document.getElementById("composition");
                                var inputpack = document.getElementById("packing");
                                var inputmrp = document.getElementById("mrp");
                                var inputquantity = document.getElementById("quantity");
                                var inputrate = document.getElementById("rate");
                                var inputnonrefundablepdc = document.getElementById("nonRefundablePDC");
                                var inputrefundablepdc = document.getElementById("refundablePDC");
                                var inputMasterBatchNo = document.getElementById("masterBatchNo");
                                var inputProductBatchNo = document.getElementById("productBatchNo");


                                inputpartyname.value = productionProcess.partyName;
                                inputproductname.value = productionProcess.productName;
                                inputcomp.value = productionProcess.composition;
                                inputpack.value = productionProcess.packing;
                                inputmrp.value = productionProcess.mrp;
                                inputquantity.value = productionProcess.quantity;

                                if (productionProcessBatch != null) {
                                    inputMasterBatchNo.value = productionProcessBatch.masterBatchNo;
                                }
                                inputProductBatchNo.value = productionProcess.batchNo;

                                if (jsonObject2.userRoles != null && (jsonObject2.userRoles == 'administrator'
                                    || jsonObject2.userRoles == 'thirdPartyAdministrator')) {
                                    inputrate.value = productionProcess.rate;
                                } else {
                                    inputrate.value = "XXX";
                                }

                                inputnonrefundablepdc.value = productionProcess.nonRefundablePdc;
                                inputrefundablepdc.value = productionProcess.refundablePdc;

                                if (document.getElementById("categoryId") != null) {
                                    document.getElementById("categoryId").remove();
                                }

                                var a = document.createElement("div");
                                a.setAttribute("id", "categoryId");
                                document.getElementById("categorySpecificConfId").appendChild(a);

                                if (productionProcess.category === "TABLET") {

                                    // Strip Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "stripTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "stripTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Strip Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "stripTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.stripType;
                                    y.appendChild(z);


                                    // Base Foil Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "baseFoilTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "baseFoilTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Base Foil Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "baseFoilTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.baseFoilType;
                                    y.appendChild(z);


                                    // Tablet Colour
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "tabletColourId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "tabletColourName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Tablet Colour";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "tabletColourValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.tabletColour;
                                    y.appendChild(z);

                                } else if (productionProcess.category === "CAPSULE") {

                                    // Strip Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "stripTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "stripTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Strip Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "stripTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.stripType;
                                    y.appendChild(z);


                                    // Base Foil Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "baseFoilTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "baseFoilTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Base Foil Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "baseFoilTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.baseFoilType;
                                    y.appendChild(z);


                                    // Capsule Colour
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "capsuleColourId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "capsuleColourName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Capsule Colour";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "capsuleColourValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.capsuleColour;
                                    y.appendChild(z);

                                } else if (productionProcess.category === "LIQUID") {

                                    // Bottle Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "bottleTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Bottle Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.bottleType;
                                    y.appendChild(z);

                                    // Bottle Colour
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "bottleColourId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleColourName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Bottle Colour";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleColourValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.bottleColour;
                                    y.appendChild(z);

                                    // Liquid Colour
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "liquidColourId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "liquidColourName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Liquid Colour";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "liquidColourValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.liquidColour;
                                    y.appendChild(z);


                                    // Liquid Flavour
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "liquidFlavourId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "liquidFlavourName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Liquid Flavour";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "liquidFlavourValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.liquidFlavour;
                                    y.appendChild(z);


                                    // Cap Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "capTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "capTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Cap Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "capTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.capType;
                                    y.appendChild(z);

                                    // Dose Cap Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "doseCapTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);


                                    var y = document.createElement("div");
                                    y.setAttribute("id", "doseCapTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Dose Cap Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "doseCapTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.doseCapType;
                                    y.appendChild(z);

                                } else {

                                    // Bottle Type
                                    var x = document.createElement("div");
                                    x.setAttribute("id", "bottleTypeId");
                                    x.setAttribute("class", "flex-container");
                                    a.appendChild(x);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleTypeName");
                                    y.setAttribute("class", "flex-child magenta");
                                    x.appendChild(y);


                                    var z = document.createElement("h4");
                                    z.textContent = "Bottle Type";
                                    y.appendChild(z);

                                    var y = document.createElement("div");
                                    y.setAttribute("id", "bottleTypeValue");
                                    y.setAttribute("class", "flex-child green");
                                    x.appendChild(y);

                                    var z = document.createElement("input");
                                    z.setAttribute("type", "text");
                                    z.disabled = true;
                                    z.textContent = productionProcess.bottleType;
                                    y.appendChild(z);
                                }

                                response.data.productionProcessMetaList.forEach((productionProcessMeta) => {

                                    var y = document.createElement("tr");
                                    y.setAttribute("id", productionProcessMeta.id);
                                    document.getElementById("myTableBody").appendChild(y);

                                    var z = document.createElement("td");
                                    z.setAttribute("class", "border_class");
                                    var t = document.createTextNode(productionProcessMeta.updatedAt);
                                    z.appendChild(t);
                                    y.appendChild(z);

                                    var z = document.createElement("td");
                                    z.setAttribute("class", "border_class");
                                    var t = document.createTextNode(productionProcessMeta.stage);
                                    z.appendChild(t);

                                    if (productionProcessMeta.stage == "EXISTING_OUTER_BOX_STOCK_CHECK" || productionProcessMeta.stage == "EXISTING_INNER_BOX_STOCK_CHECK" || productionProcessMeta.stage == "EXISTING_FOIL_STOCK_CHECK" || productionProcessMeta.stage == "EXISTING_BASE_FOIL_STOCK_CHECK" || productionProcessMeta.stage == "EXISTING_LABEL_STOCK_CHECK"
                                        || productionProcessMeta.stage == "OUTER_BOX_REC" || productionProcessMeta.stage == "INNER_BOX_REC" || productionProcessMeta.stage == "FOIL_REC" || productionProcessMeta.stage == "BASE_FOIL_REC" || productionProcessMeta.stage == "LABEL_REC") {
                                        var t = document.createElement("br");
                                        z.appendChild(t);

                                        var productionStageText = "";
                                        if (productionProcessMeta.stage == "EXISTING_OUTER_BOX_STOCK_CHECK") {
                                            productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_EXISTING"];
                                        } else if (productionProcessMeta.stage == "EXISTING_INNER_BOX_STOCK_CHECK") {
                                            productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_EXISTING"];
                                        } else if (productionProcessMeta.stage == "EXISTING_FOIL_STOCK_CHECK") {
                                            productionStageText = "FOIL: " + stockDict["FOIL_EXISTING"] + " Kg";
                                        } else if (productionProcessMeta.stage == "EXISTING_BASE_FOIL_STOCK_CHECK") {
                                            productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_EXISTING"] + " Kg";
                                        } else if (productionProcessMeta.stage == "EXISTING_LABEL_STOCK_CHECK") {
                                            productionStageText = "LABEL: " + stockDict["LABEL_EXISTING"];
                                        } else if (productionProcessMeta.stage == "OUTER_BOX_REC") {
                                            productionStageText = "OUTER BOXES: " + stockDict["OUTER_BOX_NEW"];
                                        } else if (productionProcessMeta.stage == "INNER_BOX_REC") {
                                            productionStageText = "INNER BOXES: " + stockDict["INNER_BOX_NEW"];
                                        } else if (productionProcessMeta.stage == "FOIL_REC") {
                                            productionStageText = "FOIL: " + stockDict["FOIL_NEW"] + " Kg";
                                        } else if (productionProcessMeta.stage == "BASE_FOIL_REC") {
                                            productionStageText = "BASE FOIL: " + stockDict["BASE_FOIL_NEW"] + " Kg";
                                        } else if (productionProcessMeta.stage == "LABEL_REC") {
                                            productionStageText = "LABEL: " + stockDict["LABEL_NEW"];
                                        }

                                        var t = document.createTextNode(productionStageText);
                                        z.appendChild(t);
                                    }
                                    y.appendChild(z);

                                    var z = document.createElement("td");
                                    z.setAttribute("class", "border_class");
                                    var t = document.createTextNode(productionProcessMeta.updatedBy);
                                    z.appendChild(t);
                                    y.appendChild(z);

                                    var z = document.createElement("td");
                                    z.setAttribute("class", "border_class");
                                    var remarks = "Primary Operator: " + productionProcessMeta.primaryOperator + ", Machine No: "
                                        + productionProcessMeta.machineNo + ", Helpers: " + productionProcessMeta.helpers
                                        + ", Shift No: " + productionProcessMeta.shiftNo + ", Reason: " + productionProcessMeta.reason; 
                                    var t = document.createTextNode(remarks);
                                    z.appendChild(t);
                                    y.appendChild(z);
                                });
                            });
                    });
            }

            const Orders = [];

            fetch('https://cascamailsender.azurewebsites.net/getalltimeproductionorders', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                Orders.push({ label: "Order Created At: " + object.updatedAt + ", Batch No: " + object.batchNo + ", " + object.productName + ", " + object.packing + ", MRP: " + object.mrp, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>View Production Stages for an Order</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Orders</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={Orders} id="orders" name="orders">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <button class="button" onClick={handleAction}>SUBMIT</button>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Party Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="partyName" name="partyName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Name</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="productName" name="productName"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>MRP</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="mrp" name="mrp"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Rate</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="rate" name="rate"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Non Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="nonRefundablePDC" name="nonRefundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Refundable PDC</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="refundablePDC" name="refundablePDC"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Master Batch No</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="masterBatchNo" name="masterBatchNo"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Product Batch No</h4>
                        </div>

                        <div class="flex-child green">
                            <input disabled={true} type="text" id="productBatchNo" name="productBatchNo"></input>
                        </div>

                    </div>

                    <div id="categorySpecificConfId">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Production Stage Details</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <thead>
                                    <tr>
                                        <td class="border_class">Stage Updated At</td>
                                        <td class="border_class">Stage</td>
                                        <td class="border_class">Updated By</td>
                                        <td class="border_class">Details</td>
                                    </tr>
                                    <tbody id="myTableBody">
                                    </tbody>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}