import '../App.css';
import Select from 'react-select';
import axios from "axios";
import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { data } from 'jquery';
import background from "../background.jpg";
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

export function AddCostSheet() {

    const [post, setPost] = React.useState(null);
    const [post1, setPost1] = React.useState(null);
    const [post2, setPost2] = React.useState(null);
    const [post3, setPost3] = React.useState("id");
    const [isLoading, setLoading] = React.useState(true);

    if (localStorage.getItem('cascadashboard3') != null && localStorage.getItem('cascadashboard3') == 'true') {
        var username = localStorage.getItem('cascadashboard3-username');
        var password = localStorage.getItem('cascadashboard3-password');

        const formData = new FormData();

        formData.append("username", username);
        formData.append("password", password);

        fetch('https://cascamailsender.azurewebsites.net/dashboardUserLoginRead/',
            {
                method: "POST",
                body: formData
            },
            { mode: 'cors' })
            .then(response => response.text())
            .then(data => {
                setPost3(data);
                setLoading(false);
            });

        if (isLoading) {
            return (<div style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: "fixed",
                backgroundSize: "cover"
            }} className="App">
                <h2>Loading...</h2>
            </div>);
        }

        if (post3 != null && post3 != "") {

            var postModified2 = post3.replace(/'/g, '"');
            let jsonData2 = JSON.parse(JSON.stringify(postModified2));
            let jsonObject2 = JSON.parse(jsonData2);

            const state = {
                itemType: "",
                category: "",
                composition: "",
                packing: "",
                quantity: "",
                miscCost: "",
                packagingCost: "",
                labourCost: "",
                marginPercent: "",
                rmId: "",
                rmName: "",
                quantityUsed: "",
                overages: "",
                isActiveApi: false,
                apisMap: new Map()
            };

            const handleChange = event => {
                state.rmId = event.value.id;
                state.rmName = event.value.name;
            };

            const handleDelete = (rmId) => {

                var item = document.getElementById(rmId);
                if (item != null) {
                    document.getElementById("myTable").removeChild(item);
                }
            }

            const handleRefresh = event => {

                while (RawMaterials.length > 0) {
                    RawMaterials.pop();
                }

                var url = "https://cascamailsender.azurewebsites.net/getallrawmaterials";
                axios.get(url)
                    .then((response) => {
                        response.data.forEach((activeApi) => {
                            RawMaterials.push({ label: activeApi.name, value: activeApi }); 
                        });
                    });
            }

            const handleSubmit = event => {
                var rmDetail = "[";

                var table = document.getElementById("myTable");
                for (var i = 1, row; row = table.rows[i]; i++) {
                    rmDetail += "{"
                        + "\"rmId\":" + row.cells[0].textContent
                        + ",\"quantity\":" + row.cells[2].textContent
                        + ",\"overage\":" + row.cells[3].textContent
                        + ",\"isActiveApi\":\"" + row.cells[4].textContent
                        + "\"},";
                }

                var rmDetail = rmDetail.substring(0, rmDetail.length - 1);
                rmDetail += "]"


                var jsonRequest = "{\"composition\":\"" + state.composition
                    + "\",\"category\":\"" + state.category
                    + "\",\"itemType\":\"" + state.itemType
                    + "\",\"quantity\":" + state.quantity
                    + ",\"packing\":\"" + state.packing
                    + "\",\"miscCost\":" + state.miscCost
                    + ",\"packagingCost\":" + state.packagingCost
                    + ",\"labourCost\":" + state.labourCost
                    + ",\"marginPercent\":" + state.marginPercent
                    + ",\"costSheetRMDetailList\":" + rmDetail
                    + "}";

                const options = {
                    method: 'POST',
                    url: 'https://cascamailsender.azurewebsites.net/addcostsheet',
                    headers: {
                        'content-type': 'application/json'
                    },
                    data: jsonRequest,
                };

                axios
                    .request(options)
                    .then(function (response) {
                        alert(response.data);
                    })
                    .catch(function (error) {
                        console.error(error);
                    });
            }

            const handleAction = event => {


                var uuid = uuidv4();
                var rmId = state.rmId + "_" + uuid;
                var y = document.createElement("tr");
                y.setAttribute("id", rmId);
                document.getElementById("myTable").appendChild(y);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createTextNode(state.rmId);
                z.appendChild(t);
                y.appendChild(z);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createTextNode(state.rmName);
                z.appendChild(t);
                y.appendChild(z);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createTextNode(state.quantityUsed);
                z.appendChild(t);
                y.appendChild(z);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createTextNode(state.overages);
                z.appendChild(t);
                y.appendChild(z);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createTextNode(state.isActiveApi);
                z.appendChild(t);
                y.appendChild(z);

                var z = document.createElement("td");
                z.setAttribute("class", "border_class");
                var t = document.createElement("button");
                t.setAttribute("id", rmId);
                t.textContent = "Delete";
                t.addEventListener('click', function () {
                    handleDelete(rmId);
                });
                z.appendChild(t);
                y.appendChild(z);
            };

            const Categories = [
                { label: "Tablet", value: "Tablet" },
                { label: "Capsules", value: "Capsules" },
                { label: "Syrup", value: "Syrup" },
                { label: "Dry Syrup", value: "Dry Syrup" }
            ]

            const ItemTypes = [
                { label: "Drug", value: "Drug" },
                { label: "Food", value: "Food" },
                { label: "Ayurvedic", value: "Ayurvedic" }
            ]

            let RawMaterials = [];

            fetch('https://cascamailsender.azurewebsites.net/getallrawmaterials', { mode: 'cors' })
                .then(response => response.text())
                .then(data => setPost(data));

            if (!post) return null;

            var postModified = post.replace(/'/g, '"');
            let jsonData = JSON.parse(JSON.stringify(postModified));
            let jsonObject = JSON.parse(jsonData);
            jsonObject.forEach(object => {
                RawMaterials.push({ label: object.name, value: object });
            })

            return (
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover"
                }} className="App">
                    <h2 style={{ color: '#047BD5' }}>Add Cost Sheet</h2>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Composition</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.composition = event.target.value; }} type="text" id="composition" name="composition"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Item Type</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={event => { state.itemType = event.value; }} options={ItemTypes} id="itemType" name="itemType">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Category</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={event => { state.category = event.value; }} options={Categories} id="category" name="category">
                                    </Select>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packing</h4>
                        </div>

                        <div class="flex-child green">
                                <input onChange={event => { state.packing = event.target.value; }} type="text" id="packing" name="packing"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.quantity = event.target.value; }} type="text" id="quantity" name="quantity"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Misc Cost</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.miscCost = event.target.value; }} type="number" step="0.01" id="miscCost" name="miscCost"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Packaging Cost</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.packagingCost = event.target.value; }} type="number" step="0.01" id="packagingCost" name="packagingCost"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Labour Cost</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.labourCost = event.target.value; }} type="number" step="0.01" id="labourCost" name="labourCost"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Margin Percent</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.marginPercent = event.target.value; }} type="number" step="0.01" id="marginPercent" name="marginPercent"></input>
                        </div>

                    </div>


                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Raw Material</h4>
                        </div>

                        <div class="flex-child green">
                            <div className="row">
                                <div className="col-md-6">
                                    <Select onChange={handleChange.bind(this)} options={RawMaterials} id="rawmaterial" name="rawmaterial">
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div class="flex-child yellow">
                            <button class="button" onClick={handleRefresh}>REFRESH RM</button>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Quantity Used</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.quantityUsed = event.target.value; }} type="number" step="0.01" id="quantityUsed" name="quantityUsed"></input>
                        </div>

                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Overages</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.overages = event.target.value; }} type="number" step="0.01" id="overages" name="overages"></input>
                        </div>
                    </div>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>Is Active API</h4>
                        </div>

                        <div class="flex-child green">
                            <input onChange={event => { state.isActiveApi = event.target.value; }} type="boolean" id="isActiveApi" name="isActiveApi"></input>
                        </div>
                    </div>

                    <button class="button" onClick={handleAction}>ADD</button>

                    <div class="flex-container">

                        <div class="flex-child magenta">
                            <h4>RM Used</h4>
                        </div>

                        <div class="flex-child green">
                            <table class="border_class" id="myTable">
                                <tr>
                                    <td class="border_class">RM Id</td>
                                    <td class="border_class">RM Name</td>
                                    <td class="border_class">Quantity Used</td>
                                    <td class="border_class">Overages</td>
                                    <td class="border_class">Is Active API</td>
                                </tr>
                            </table>
                        </div>

                    </div>                 

                    <button class="button" onClick={handleSubmit}>SUBMIT COST SHEET</button>

                </div>
            );
        } else {
            return (
                <div>
                    <Router>
                        <Switch>


                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </div>
            );
        }
    } else {
        return (
            <div>
                <Router>
                    <Switch>


                        <Redirect to="/" />
                    </Switch>
                </Router>
            </div>
        );
    }
}